const html = require('choo/html');
const Component = require('choo/component');
const version = require('../../package.json').version;
/*const { browserName } = require('../utils');*/

class Footer extends Component {
  constructor(name, state) {
    super(name);
    this.state = state;
  }

  update() {
    return false;
  }

  createElement() {
    return html`
      <footer
        class="flex flex-col md:flex-row items-start w-full flex-none self-start p-6 md:p-8 font-medium text-xs text-grey-60 dark:text-grey-40 md:justify-end justify-between"
      >
        <a
          href="https://admin.chapril.org/doku.php?id=admin:chatons:drop.chapril.org#personnalisation"
          rel="noreferrer noopener"
          alt="Version"
          target="_blank"
        >
          FirefoxSend chapril-${version}
        </a>
      </footer>
    `;
  }
}

module.exports = Footer;
