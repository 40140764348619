const html = require('choo/html');
const Component = require('choo/component');
const Account = require('./account');
/*const assets = require('../../common/assets');*/
/*const { platform } = require('../utils');*/

class Header extends Component {
  constructor(name, state, emit) {
    super(name);
    this.state = state;
    this.emit = emit;
    this.account = state.cache(Account, 'account');
  }

  update() {
    this.account.render();
    return false;
  }

  createElement() {
    return html`
      <header
        class="main-header relative flex-none flex flex-row items-center justify-between px-6 md:px-8 z-20 bg-transparent"
      >
        <!-- Chapril banner code: starting… -->
        <nav class="chapril-banniere-Nav">
          <div class="chapril-banniere-Logo" aria-hidden="true">
            <a href="https://www.chapril.org"
              ><img alt="" src="/Chapril-banner/v2/chapril-banner-logo.png"
            /></a>
          </div>
          <div class="chapril-banniere-Menu" aria-hidden="true">
            <details>
              <summary>
                <span></span>
                <span></span>
                <span></span>
              </summary>
              <div><a href="https://www.chapril.org/">Accueil Chapril</a></div>
              <div>
                <a href="https://www.chapril.org/services.html"
                  >Services libres</a
                >
              </div>
              <div>
                <a href="https://pouet.april.org/@aprilorg">Nous suivre</a>
              </div>
              <div><a href="https://www.chapril.org/cgu.html">CGU</a></div>
              <div>
                <a href="https://www.chapril.org/a-propos.html"
                  >Mentions légales</a
                >
              </div>
              <div>
                <a href="https://www.chapril.org/contact.html"
                  >Nous contacter</a
                >
              </div>
            </details>
          </div>
          <div class="chapril-banniere-Entree">
            <a href="https://www.chapril.org/">Accueil Chapril</a>
          </div>
          <div class="chapril-banniere-Entree">
            <a href="https://www.chapril.org/services.html">Services libres</a>
          </div>
          <div class="chapril-banniere-Entree">
            <a href="https://pouet.april.org/@aprilorg">Nous suivre</a>
          </div>
          <div class="chapril-banniere-Entree">
            <a href="https://www.chapril.org/cgu.html">CGU</a>
          </div>
          <div class="chapril-banniere-Entree">
            <a href="https://www.chapril.org/a-propos.html">Mentions légales</a>
          </div>
          <div class="chapril-banniere-Entree">
            <a href="https://www.chapril.org/contact.html">Nous contacter</a>
          </div>
        </nav>
        <!-- Chapril banner code: done. -->
      </header>
    `;
  }
}

module.exports = Header;
